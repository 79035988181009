body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.sectionHeader {
    padding-top: 60px;
}


html{
    scroll-behavior: smooth;
}


body {
    font-family: 'Chivo', sans-serif;
  }

footer{
    margin-bottom: -15px;
}

.container{
   max-width: 1200px;
}

#Header_TitleText__2OGiv{
    margin: -15px;
}


#Header_background__2wzBS {
    background-image: url("/images/connectwork.png");
    overflow: hidden;
    height: 100vh;
}

#Header_headerImage__2H8Hr{
    height: 100vh;
}

#Navbar_headerName__BYxxf {
    font-size: 20px;
}
.Navbar_navbarFixed__3eJGI {
    position: absolute;
    /* z-index:999999999; */
}

#Navbar_navBar__2QXAE {
    border-bottom: 1px solid;
}

.Navbar_fab__33-6O {
  color:#0d47a1; 
  margin-right: 10px;
}
#About_skillsCont__BxFdC{
    min-height: 100vh;
}

.About_skillIcons__2yKLW{
    font-size: 80px;
    color: #0d47a1;
}


#About_bioImage__2r78-{
    max-width: 100%;
    height: auto;
}

.About_shadowed__3xD6p {
    -webkit-filter: drop-shadow(5px 5px 5px #222);
    filter: drop-shadow(5px 5px 5px #222);
}

.Technical_techName__2rJJP {
    text-align: center;
    font-weight: bolder;
}
#Projects_projectsCont__GbiiZ {
  min-height: 100vh;
}

.Projects_project__1GVpR {
  margin-bottom: 150px;
}

.Projects_portfolioImg__1cLVr {
  width: 100%;
}

.Projects_button__2PkOv {
  margin: 5px;
}

#Contact_contactCont__1htNG {
    min-height: 100vh;
}
