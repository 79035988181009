#headerName {
    font-size: 20px;
}
.navbarFixed {
    position: absolute;
    /* z-index:999999999; */
}

#navBar {
    border-bottom: 1px solid;
}

.fab {
  color:#0d47a1; 
  margin-right: 10px;
}