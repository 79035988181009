#projectsCont {
  min-height: 100vh;
}

.project {
  margin-bottom: 150px;
}

.portfolioImg {
  width: 100%;
}

.button {
  margin: 5px;
}
