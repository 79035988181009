#skillsCont{
    min-height: 100vh;
}

.skillIcons{
    font-size: 80px;
    color: #0d47a1;
}


#bioImage{
    max-width: 100%;
    height: auto;
}

.shadowed {
    -webkit-filter: drop-shadow(5px 5px 5px #222);
    filter: drop-shadow(5px 5px 5px #222);
}