
html{
    scroll-behavior: smooth;
}


body {
    font-family: 'Chivo', sans-serif;
  }

footer{
    margin-bottom: -15px;
}

.container{
   max-width: 1200px;
}
